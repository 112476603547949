import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Content from "../components/content"
import Item from "../components/item"
import Friendlink from "../components/friendlink"
import Style from "../styles/index.module.css"
import SEO from "../components/seo"
import JsonData from "../../content/index.json"

export default () => (
    <Layout>
        <SEO title={"首页"} />
        <Header nav="index" />
        <Content>
            {
                JsonData.map((val, index) => (
                    <div key={index} className={Style.box}>
                        <Item obj={val} titleUrl={`/class/${index + 1}/`} />
                    </div>
                ))
            }
            <Friendlink />
        </Content>
    </Layout>
)
