import React from "react"
import Style from "../styles/friendlink.module.css"

export default () => (
    <div className={Style.box}>
        <h2>友情链接</h2>
        <div>
            <a href="https://like-gif.com" target="_blank">妹子gif</a>
            <a href="https://52haha.xyz" target="_blank">搞笑段子</a>
        </div>
    </div>
)